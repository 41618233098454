<!--
 * @Author: wyq
 * @Date: 2021-09-01 14:46:11
 * @LastEditTime: 2021-10-28 17:52:34
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\knowledgeGraph\editTreeDialog.vue
-->
<template>
  <el-dialog
    :title="type=='edit'?'编辑知识图谱':'添加知识图谱'"
    :visible.sync="dialogVisible"
    width="400px"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top" size="mini">
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <template v-if="desc">
        <el-form-item label="说明">
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item>
          <upload-img :file-list.sync="form.images"></upload-img>
        </el-form-item>
      </template>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import uploadImg from '@/components/edit/uploadImg'
import mixin from '@/utils/dialogMixin'
export default {
  mixins: [mixin],
  props: {
    value: Object,
    type: String,
    desc: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    uploadImg,
  },
  data() {
    return {
      form: {
        name: '',
        desc: '',
        images: [],
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      },
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          if (this.type == 'edit') {
            this.form = {
              name: this.value.label,
              desc: this.value.description,
              images: this.value.images
                ? this.value.images.map((i) => {
                    return {
                      label: i.name,
                      url: i.url,
                    }
                  })
                : [],
            }
          }
        } else {
          this.form = {
            name: '',
            desc: '',
            images: [],
          }
          this.$refs['form'].resetFields()
        }
      },
      immediate: true,
    },
  },
  methods: {
    submit() {
      this.form.name = this.form.name.trim()
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.type == 'edit') {
            console.log('编辑成功')
            let params = {
              id: this.value.id,
              label: this.form.name,
              description: this.form.desc,
              images: this.form.images.map((i) => {
                return {
                  name: i.label,
                  url: i.response ? i.response.Data.url : i.url,
                }
              }),
            }
            this.$emit('updateLabel', params, 'tree')
          } else {
            console.log('添加成功')
            let params = {
              label: this.form.name,
              parentId: this.value.id,
              grade: this.grade,
              subject: this.subject,
              description: this.form.desc,
              images: this.form.images.map((i) => {
                return {
                  name: i.label,
                  url: i.response ? i.response.Data.url : i.url,
                }
              }),
            }
            this.$emit('createLabel', params, 'tree')
          }
          this.dialogVisible = false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
</style>