<!--
 * @Author: wyq
 * @Date: 2021-06-21 09:58:06
 * @LastEditTime: 2021-10-27 14:49:14
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\edit\uploadImg.vue
-->
<template>
  <div class="upload-image">
    <el-upload
      class="uploader"
      list-type="picture-card"
      :action="photoUrl"
      :headers="headers"
      name="photo"
      :file-list="fileList"
      :on-change="handleChange"
      :on-success="onSuccess"
      accept=".png,.jpg,.gif"
      :before-upload="beforeUpload"
    >
      <i class="el-icon-plus"></i>
      <span>上传图片</span>
      <div slot="file" slot-scope="{file}">
        <img class="el-upload-list__item-thumbnail" :src="file.url" />
        <div v-if="!noName" class="img-name">{{file.label}}</div>
        <span class="el-upload-list__item-actions">
          <span @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span v-if="!noName" @click="handleChangeName(file)">
            <i class="el-icon-edit"></i>
          </span>
          <span @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <div class="preview-image">
        <img :src="dialogImageUrl" />
      </div>
    </el-dialog>
    <el-dialog title="编辑图片名称" :visible.sync="dialogFormVisible" width="380px">
      <el-form :model="form" label-position="top">
        <el-form-item label="图片名称">
          <el-input v-model="form.imgName" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitShowName">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    fileList: Array,
    noName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      dialogFormVisible: false,
      form: {
        imgName: '',
      },
      currentFile: {},
      currentFileList: [],
    }
  },
  computed: {
    photoUrl() {
      return (
        window.location.protocol +
        '//' +
        window.location.host +
        '/v1/photo/upload'
      )
    },
    headers() {
      return {
        Authorization: localStorage.getItem('token'),
      }
    },
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleChange(file, fileList) {
      file.label = '图' + fileList.length
      this.currentFileList = fileList
    },
    onSuccess(response, file, fileList) {
      console.log('onSuccess', response, file, fileList)
      this.$emit('update:fileList', fileList)
    },
    handleChangeName(file) {
      this.currentFile = file
      this.form.imgName = file.label
      this.dialogFormVisible = true
    },
    submitShowName() {
      this.currentFile.label = this.form.imgName
      this.$emit('update:fileList', this.fileList)
      this.dialogFormVisible = false
    },
    handleRemove(file) {
      const index = this.fileList.findIndex((i) => i.uid == file.uid)
      this.fileList.splice(index, 1)
      this.$emit('update:fileList', this.fileList)
    },
    beforeUpload(file) {
      console.log(file)
      // const isPNG = file.type === 'image/png'
      // const isLt100KB = file.size < 300 * 1024
      // if (!isPNG) {
      //   this.$message.error('上传图片只能是 PNG 格式!')
      // } else if (!isLt100KB) {
      //   this.$message.error('上传图片大小不能超过 300KB!')
      // }
      // return isPNG && isLt100KB
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-image {
  .img-name {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    text-align: center;
    background-color: rgba($color: #000000, $alpha: 0.6);
    color: #fff;
  }
  .preview-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    img {
      max-height: 100%;
    }
  }
}
</style>
