<!--
 * @Author: wyq
 * @Date: 2021-09-01 14:46:57
 * @LastEditTime: 2021-09-16 15:54:12
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\knowledgeGraph\editClassificationDialog.vue
-->
<template>
  <el-dialog
    :title="type == 'edit'?'编辑试题分类':'添加试题分类'"
    :visible.sync="dialogVisible"
    width="400px"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
      size="mini"
      @keyup.enter.native="submit"
      @submit.native.prevent
    >
      <el-form-item prop="name">
        <el-input placeholder="请输入名称" v-model="form.name" size="mini"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import mixin from '@/utils/dialogMixin'
export default {
  mixins: [mixin],
  props: {
    value: Object,
    type: String,
  },
  data() {
    return {
      form: {
        name: '',
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      },
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          if (this.type == 'edit') {
            this.form.name = this.value.name
          } else {
            this.form.name = ''
          }
        } else {
          this.form.name = ''
          this.$refs['form'].resetFields()
        }
      },
      immediate: true,
    },
  },
  methods: {
    submit() {
      this.form.name = this.form.name.trim()
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.type == 'edit') {
            console.log('编辑成功')
            let params = {
              name: this.form.name,
              id: this.value.id,
            }
            this.$service.updateCategory(params).then(() => {
              this.$emit('getCategoryList')
              this.$message({
                type: 'success',
                message: '编辑成功!',
              })
            })
          } else {
            console.log('添加成功')
            let params = {
              name: this.form.name,
              bookId: this.value.id,
            }
            this.$service.createCategory(params).then(() => {
              this.$emit('getCategoryList')
              this.$message({
                type: 'success',
                message: '添加成功!',
              })
            })
          }
          this.dialogVisible = false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
</style>